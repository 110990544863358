import * as Sentry from '@sentry/react';
import { gql, useApolloClient } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { assign } from 'xstate';
import { SecurityProvider } from '@ucc/react/security';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ViewerDocument } from '~/gql/ucc/graphql';
import { AppLoader } from './AppLoader';
import log from '~/log';

export const viewerQuery = gql`
    query Viewer {
        viewer {
            email
            firstName
            lastName
            avatar
            permissions {
                id
                permissions
            }
            app {
                mitarbeiterId
            }
        }
    }
`;

export function Root() {
    const client = useApolloClient();
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <SecurityProvider
            services={{
                fetchUserData: async (context: any, event: any) => {
                    const viewer = await client
                        .query({ query: ViewerDocument })
                        .then(({ data }) => data.viewer)
                        .catch((error) => {
                            log.debug('auth.viewer', error);
                            Sentry.captureMessage('[AuthMachine]: missing viewer', {
                                extra: error,
                            });
                            throw error;
                        });
                    log.debug('auth.fetchUserData.start', context, event, viewer);
                    if (!viewer?.app.mitarbeiterId) {
                        log.debug('auth.viewer.app', viewer?.app);
                        Sentry.captureMessage(
                            '[AuthMachine]: throwed custom error "missing app.mitarbeiterId"'
                        );
                        throw new Error('missing app.mitarbeiterId');
                    }
                    if (viewer?.permissions.length === 0) {
                        log.debug('auth.viewer.permissions', viewer?.permissions);
                        Sentry.captureMessage(
                            '[AuthMachine]: throwed custom error "missing permissions"'
                        );
                        throw new Error('missing permissions');
                    }
                    const userData = {
                        ...viewer,
                        name: [viewer.firstName, viewer.lastName].filter(Boolean).join(' '),
                    };
                    log.debug('auth.fetchUserData.end', userData);
                    return userData;
                },
                logout: async () => {
                    Sentry.captureMessage('[AuthMachine]: Logout was triggered');
                    Auth.signOut().then(() => {
                        client.clearStore();
                    });
                },
            }}
            actions={{
                navigateToHome: () => {
                    if (location.pathname === '/auth') {
                        navigate('/');
                    }
                },
                navigateToLogin: () => {
                    navigate('/auth');
                },
                setUserData: assign((context, event: any) => {
                    console.log('auth.setUserData', { context, event });
                    return { viewer: event?.data, error: null };
                }),
            }}
        >
            <AppLoader>
                <Outlet />
            </AppLoader>
        </SecurityProvider>
    );
}
